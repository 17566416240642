import { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import Loader from './layout/Loader';
import PageTitle from './components/PageTitle';
import SignIn from './pages/Authentication/SignIn';
import SignUp from './pages/Authentication/SignUp';
import DefaultLayout from './layout/DefaultLayout';
import { Toaster } from 'react-hot-toast';
import Profile from './pages/Profile';
import { userExists } from './services/request-helper';
import Buckets from './pages/Buckets';
import BucketCreate from './pages/Buckets/BucketCreate';
import BucketsObjects from './pages/Buckets/BucketsObjects';
import BucketsObject from './pages/Buckets/BucketsObject';
import useColorMode from './hooks/useColorMode';
const PATHS_NOT_NEED_HEADER_FOOTER = ["/auth/signup", "/auth/signin"]

const PrivateRoute: React.FC = () => {
  return userExists() ? (
    <><Outlet /></>
  ) : (
    <Navigate to="/auth/signin" replace />
  );
};

function App() {
  useColorMode()
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => { 
    if (PATHS_NOT_NEED_HEADER_FOOTER?.includes(pathname)){
      if (userExists()){
        window.location.href = '/dashboard'
      }
    } else if (!PATHS_NOT_NEED_HEADER_FOOTER?.includes(pathname) && !userExists()){
      window.location.href = '/auth/signin'
    }
  }, [pathname])
  
  return loading ? (
    <Loader />
  ) : (
    <DefaultLayout>
      <Routes>
        <Route path="/" element={<PrivateRoute />} >
          <Route
            index
            element={
              <>
                <PageTitle title="Dashboard | Buynow Depot CDN" />
                <h1>Dashboard</h1>
              </>
            }
          />
          {/* <Route
            path='/instances'
            element={
              <>
                <PageTitle title="Instances | Buynow Depot CDN" />
                <Instances />
              </>
            }
          /> */}
          <Route
              path='/buckets'
            element={
              <>
                <PageTitle title="Buckets | Buynow Depot CDN" />
                <Buckets />
              </>
            }
          />
          <Route
              path='/buckets/:id'
            element={
              <>
                <PageTitle title="Buckets | Buynow Depot CDN" />
                <BucketsObjects />
              </>
            }
          />
          <Route
              path='/buckets/:id/:folder'
            element={
              <>
                <PageTitle title="Buckets | Buynow Depot CDN" />
                <BucketsObject />
              </>
            }
          />
          <Route
              path='/bucket-create'
            element={
              <>
                <PageTitle title="Create Buckets | Buynow Depot CDN" />
                <BucketCreate />
              </>
            }
          />
          <Route
            path='/profile'
            element={
              <>
                <PageTitle title="Profile | Buynow Depot CDN" />
                <Profile />
              </>
            }
          />
        </Route>
        <Route
          path="/auth/signin"
          index
          element={
            <>
              <PageTitle title="SignIn | Buynow Depot CDN" />
              <SignIn />
            </>
          }
        />
        <Route
          path="/auth/signup"
          element={
            <>
              <PageTitle title="SignUp | Buynow Depot CDN" />
              <SignUp />
            </>
          }
        />
      </Routes>
      <Toaster
        position="top-right"
        reverseOrder={true}
      />
    </DefaultLayout>
  );
}

export default App;
