/* eslint-disable no-useless-escape */
import * as Yup from 'yup'

export const signInSchema = Yup.object().shape({
    username: Yup.string().email('Email must be a valid email address').required("Email is required"),
    password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters")
        .matches(/(?=.*[A-Z])/, "Password must contain at least one capital letter") 
        .matches(/(?=.*[!@#\$%\^&\*])/, "Password must contain at least one special character"),
});

export interface ISignInSchema extends Yup.Asserts<typeof signInSchema> { }

export const signUpSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email('Email must be a valid email address').required("Email is required"),
    password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters")
        .matches(/(?=.*[A-Z])/, "Password must contain at least one capital letter")
        .matches(/(?=.*[!@#\$%\^&\*])/, "Password must contain at least one special character"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], "Passwords must match")
        .required("Confirm Password is required"),
});

export interface ISignUpSchema extends Yup.Asserts<typeof signUpSchema> { }
export interface ISignInSchema extends Yup.Asserts<typeof signInSchema> { }

export const createBucketSchema = Yup.object().shape({
    name: Yup.string().required("Bucket name is required"),
    objectOwnership: Yup.string().default('enabled')
        .oneOf(['disabled', 'enabled'], "Object ownership must be either 'disabled' or 'enabled'")
        .optional(),
    bucketVersioning: Yup.string().default('enabled')
        .oneOf(['disable', 'enable'], "Bucket versioning must be either 'disable' or 'enable'")
        .optional(),
    tags: Yup.array()
        .of(Yup.string().required("Tag is required"))
        .optional(),
    encryptionType: Yup.string().default('sse-s3')
        .oneOf(['sse-s3', 'sse-kms', 'dual-layer'], "Encryption type must be one of the specified options")
        .optional(),
    bucketKey: Yup.string().default('disable')
        .oneOf(['disable', 'enable'], "Bucket key must be either 'disable' or 'enable'")
        .optional(),
});

export interface ICreateBucketSchema extends Yup.Asserts<typeof createBucketSchema> { }