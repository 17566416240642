import { FormProvider, useForm, Controller } from 'react-hook-form';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import InputField from '../../components/InputField';
import LoadingButton from '../../layout/LoadingButton';
import { createBucketSchema, ICreateBucketSchema } from '../../helper/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const BucketCreate = () => {
    const [tags, setTags] = useState<string[]>([]);
    const [newTagKey, setNewTagKey] = useState('');


    const methods = useForm<ICreateBucketSchema>({
        resolver: yupResolver(createBucketSchema),
        defaultValues:{
            objectOwnership: 'enabled',
            bucketVersioning: "enable",
            bucketKey: 'disable',
            encryptionType: 'sse-s3'
        },
        mode: "onSubmit"
    });

    const handleAddTag = () => {
        if (newTagKey) {
            setTags([...tags, newTagKey]);
            setNewTagKey('');
        }
    };

    const handleDeleteTag = (index: number) => {
        const updatedTags = tags.filter((_, i) => i !== index);
        setTags(updatedTags);
    };

    const onSubmit = async (data: ICreateBucketSchema) => {
        try {
            console.log("submit--create--bucket", data);
        } catch (error) {
            console.error("Error while creating bucket:", error);
        }
    };

    return (
        <>
            <Breadcrumb items={[{ path: "/buckets", name: "Buckets" }, { path: "/bucket-create", name: "Create Bucket" }]} />

            <div className="flex flex-col gap-10 max-w-3xl">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className='flex flex-col gap-10'>

                        <div className="p-6 bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <h2 className="text-lg font-semibold mb-4">General Configuration</h2>
                            <p className="text-gray-600 mb-4 dark:text-gray-300">AWS Region: ap-southeast-2</p>

                            <div className="mb-4">
                                <InputField name='name' type='text' placeholder='Enter your bucket name' label='Bucket Name' />
                            </div>
                        </div>

                        <div className="p-6 bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <h2 className="text-lg font-semibold mb-4">Object Ownership</h2>
                            <p className="text-gray-600 mb-4 dark:text-gray-300">
                                Control ownership of objects written to this bucket from other AWS accounts and the use of access control lists (ACLs). Object ownership determines who can specify access to objects.
                            </p>

                            <div className='grid grid-cols-2 gap-4'>
                                <label className="mb-4 border p-4 dark:border-gray-600">
                                    <div className="inline-flex items-center">
                                        <Controller
                                            name="objectOwnership"
                                            control={methods.control}
                                            render={({ field }) => (
                                                <input type="radio" {...field} checked={field.value === 'disabled'} value="disabled" className="form-radio text-blue-600" />
                                            )}
                                        />
                                        <span className="ml-2">ACLs disabled (recommended)</span>
                                    </div>
                                    <p className="text-gray-500 text-sm ml-8 dark:text-gray-400">
                                        All objects in this bucket are owned by this account. Access to this bucket and its objects is specified using only policies.
                                    </p>
                                </label>

                                <label className="mb-4 border p-4 dark:border-gray-600">
                                    <div className="inline-flex items-center">
                                        <Controller
                                            name="objectOwnership"
                                            control={methods.control}
                                            render={({ field }) => (
                                                <input type="radio" {...field} checked={field.value === 'enabled'} value="enabled" className="form-radio text-blue-600" />
                                            )}
                                        />
                                        <span className="ml-2">ACLs enabled</span>
                                    </div>
                                    <p className="text-gray-500 text-sm ml-8 dark:text-gray-400">
                                        Objects in this bucket can be owned by other AWS accounts. Access to this bucket and its objects can be specified using ACLs.
                                    </p>
                                </label>
                            </div>

                            <div className="mt-4">
                                <h3 className="font-semibold">Object Ownership</h3>
                                <p className="text-gray-600 dark:text-gray-300">Bucket owner enforced</p>
                            </div>
                        </div>

                        <div className="p-6 bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <h2 className="text-lg font-semibold mb-4">Bucket Versioning</h2>
                            <p className="text-gray-600 mb-4 dark:text-gray-300">
                                Versioning is a means of keeping multiple variants of an object in the same bucket. You can use versioning to preserve, retrieve, and restore every version of every object stored in your Amazon S3 bucket. With versioning, you can easily recover from both unintended user actions and application failures. <Link to="#" className="text-blue-500 underline">Learn more</Link>
                            </p>

                            <div className="mb-2">
                                <InputField name='bucketVersioning' type='radio' placeholder='Enter your' label='Disable' value="disable"  />
                            </div>

                            <div className="mb-4">
                                <InputField name='bucketVersioning' type='radio' placeholder='Enter your' label='Enable' value="enable" />
                            </div>
                        </div>

                        <div className="p-6 bg-white rounded-lg shadow-md mb-6 dark:bg-gray-800">
                            <h2 className="text-lg font-semibold mb-4">Tags - <span className="text-gray-500">(optional)</span> ({tags.length})</h2>
                            <p className="text-gray-600 mb-2 dark:text-gray-300">
                                You can use bucket tags to track storage costs and organize buckets. <Link to="#" className="text-blue-500 underline">Learn more</Link>
                            </p>
                            <p className="text-gray-500 mb-4 dark:text-gray-400">
                                {tags?.length === 0 ? 'No tags associated with this bucket.' : ''}
                            </p>

                            <div className="mb-4">
                                <input
                                    type="text"
                                    placeholder="Tag"
                                    value={newTagKey}
                                    onChange={(e) => setNewTagKey(e.target.value)}
                                    className="border border-gray-300 rounded p-2 mr-2"
                                />
                                <button
                                    type='button'
                                    onClick={handleAddTag}
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                >
                                    Add tag
                                </button>
                            </div>

                            <ul className="list-disc pl-5">
                                {tags.map((tag, index) => (
                                    <li key={index} className="flex justify-between items-center mb-2">
                                        <span>{tag}</span>
                                        <button
                                            type='button'
                                            onClick={() => handleDeleteTag(index)}
                                            className="text-red-500 hover:underline"
                                        >
                                            Delete
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="p-6 bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <h2 className="text-lg font-semibold mb-4">Default encryption <span className="text-gray-500">Info</span></h2>
                            <p className="text-gray-600 mb-4 dark:text-gray-300">
                                Server-side encryption is automatically applied to new objects stored in this bucket.
                            </p>

                            <h3 className="font-semibold mb-2">Encryption type <span className="text-gray-500">Info</span></h3>
                            <div className="mb-4">
                                <InputField name='encryptionType' type='radio' placeholder='Enter your' label='Server-side encryption with Amazon S3 managed keys (SSE-S3)' value="sse-s3" />
                            </div>
                            <div className="mb-4">
                                <InputField name='encryptionType' type='radio' placeholder='Enter your' label='Server-side encryption with AWS Key Management Service keys (SSE-KMS)' value="sse-kms" />
                            </div>
                            <div className="mb-4">
                                <InputField name='encryptionType' type='radio' placeholder='Enter your' label='Dual-layer server-side encryption with AWS Key Management Service keys (DSSE-KMS)' value="dual-layer" />
                                <p className="text-gray-500 text-sm ml-8 dark:text-gray-400">
                                    Secure your objects with two separate layers of encryption. For details on pricing, see <Link to="#" className="text-blue-500 underline">DSSE-KMS pricing</Link> tab of the Amazon S3 pricing page.
                                </p>
                            </div>

                            <h3 className="font-semibold mb-2">Bucket Key</h3>
                            <p className="text-gray-600 mb-4 dark:text-gray-300">
                                Using an S3 Bucket Key for SSE-KMS reduces encryption costs by lowering calls to AWS KMS. S3 Bucket Keys aren't supported for DSSE-KMS.
                            </p>
                            <div className="mb-2">
                                <InputField name='bucketKey' type='radio' placeholder='Enter your' label='Disable' value="disable" />
                            </div>
                            <div className="mb-4">
                                <InputField name='bucketKey' type='radio' placeholder='Enter your' label='Enable' value="enable" />
                            </div>
                        </div>

                        <div className="mb-5 grid grid-cols-2 items-center gap-4">
                            <Button to='/buckets' variant='outlined' className='block w-full'>Cancel</Button>
                            <LoadingButton className='w-fit py-2.5' type='submit' loading={false}>Submit</LoadingButton>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </>
    );
};

export default BucketCreate;