import React from 'react';

interface LoadingButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
    children: React.ReactNode;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({ loading, children, ...props }) => {
    return (
        <button
            {...props}
            disabled={loading} // Disable button when loading
            className={`w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90 text-center font-medium lg:px-8 xl:px-10  ${loading ? 'opacity-50' : ''} ${props?.className || ''}`}
        >
            {loading ? (
                <span>Loading...</span> // You can replace this with a spinner component if desired
            ) : (
                children
            )}
        </button>
    );
};

export default LoadingButton;