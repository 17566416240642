import React from 'react';
import { Column, useTable, useSortBy } from 'react-table';
import ItemsPerPageDropdown from '../Pagination/ItemsPerPageDropdown';
import PaginationComponent from '../Pagination/PaginationComponent';

interface DataTableProps {
    data: any[]; // Array of Package type
    columns: Column<any>[]; // Array of Column type with Package
}

const DataTable: React.FC<DataTableProps> = ({ data, columns }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable<any>(
        {
            columns,
            data,
        },
        useSortBy // Add useSortBy hook here
    );

    return (
        <>
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark p-4">
                <div className="max-w-full overflow-x-auto">
                    <table {...getTableProps()} className="w-full table-auto">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-2 text-left dark:bg-meta-4">
                                    {headerGroup.headers.map((column: any) => (
                                        <th
                                            {...column.getHeaderProps(column?.getSortByToggleProps())} // Add sorting props
                                            className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11"
                                        >
                                            {column.render('Header')}
                                            {/* Add sorting indicator */}
                                            <span>
                                                {column?.isSorted
                                                    ? column?.isSortedDesc
                                                        ? ' 🔽' // Descending
                                                        : ' 🔼' // Ascending
                                                    : ''}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} className="hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors cursor-pointer" >
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()} className="border-b border-[#eee] py-5 px-4 dark:border-strokedark xl:pl-11">
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='flex justify-between mt-7 items-center'>
                <ItemsPerPageDropdown
                    handleChange={(number)=>{
                        console.log("Hey ItemsPerPageDropdown", number);
                        
                    }}
                />
                <PaginationComponent
                    currentPage={11}
                    totalPages={13}
                    onPageChange={({ selected }) => console.log("----------", selected+1)}
                />
            </div>
        </>
    );
};

export default DataTable;