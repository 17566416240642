import { handleLogout } from '../helper/auth';
import { BASE_URL } from '../helper/constants';
import { getToken, userExists } from './request-helper';

// Parse json response
function parseJSON(response: Response): any {
    return response;
}

// Check the response status
function checkStatus(response: Response): any {
    if (response.status === 410) {
        return handleLogout();
    }
    return response.json();
}

// Handle catch block of Fetch API
function handleCatch(error: any): any {
    return error;
}

// Common Request module for any API call
export default function request(url: string, options: RequestInit): Promise<any> {
    const option: RequestInit = {
        method: options.method,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        ...options
    };

    if (userExists()) {
        option.headers = {
            ...option.headers,
            Authorization: getToken(),
        };
    }
    console.log("baseurl", BASE_URL, url, option, process.env)
    return fetch(`${BASE_URL}${url}`, option).then(checkStatus).then(parseJSON).catch(handleCatch);
}

export function requestFormData(url: string, options: RequestInit): Promise<any> {
    const option: RequestInit = {
        method: options.method,
        headers: {
            Accept: 'application/json'
        }
    };

    if (options.body) {
        option.body = options.body;
    }

    if (userExists()) {
        option.headers = {
            ...option.headers,
            Authorization: getToken(),
        };
    }

    return fetch(`${BASE_URL}${url}`, option).then(checkStatus).then(parseJSON).catch(handleCatch);
}