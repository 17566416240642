import { Link } from 'react-router-dom';

interface BreadcrumbItem {
  name: string;
  path: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[]; // Changed to accept an array of breadcrumb items
  pageName ?: string
}

const Breadcrumb = ({ items, pageName="" }: BreadcrumbProps) => {
  return (
    <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
      <h2 className="text-title-md2 font-semibold text-black dark:text-white">
        {pageName || items[items.length - 1].name} {/* Display the last item's name */}
      </h2>

      <nav>
        <ol className="flex items-center gap-2">
          <li className="font-medium">
            <Link to="/">
              Dashboard /
            </Link>
          </li>
          {items.map((item, index) => (
            <li key={index} className="font-medium">
              <Link to={item.path}>
                {item.name}
                {index < items.length - 1 && ' / '} {/* Add separator except for the last item */}
              </Link>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;