import { Link } from "react-router-dom";

export default function BucketProperties() {
    return (
        <div className="p-6">
            <h2 className="text-lg font-semibold mb-4">Bucket Overview</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                <div className="bg-white dark:bg-gray-800 p-4 rounded shadow">
                    <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400">AWS Region</h3>
                    <p className="text-sm font-semibold text-gray-800 dark:text-gray-200">Asia Pacific (Sydney) ap-southeast-2</p>
                </div>
                <div className="bg-white dark:bg-gray-800 p-4 rounded shadow">
                    <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400">Amazon Resource Name (ARN)</h3>
                    <p className="text-sm font-semibold text-gray-800 dark:text-gray-200">
                        <span className="text-blue-600 dark:text-blue-400">arn:aws:s3:::social-broker</span>
                    </p>
                </div>
                <div className="bg-white dark:bg-gray-800 p-4 rounded shadow">
                    <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400">Creation date</h3>
                    <p className="text-sm font-semibold text-gray-800 dark:text-gray-200">June 23, 2023, 12:14:06 (UTC+05:30)</p>
                </div>
            </div>

            <h2 className="text-lg font-semibold mb-4">Bucket Versioning</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400">Bucket Versioning</h3>
                <p className="text-sm font-semibold text-gray-800 dark:text-gray-200">Disabled</p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400">Multi-factor authentication (MFA) delete</h3>
                <p className="text-sm font-semibold text-gray-800 dark:text-gray-200">Disabled</p>
            </div>

            <h2 className="text-lg font-semibold mb-4">Default Encryption</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400">Encryption Type</h3>
                <p className="text-sm font-semibold text-gray-800 dark:text-gray-200">Server-side encryption with Amazon S3 managed keys (SSE-S3)</p>
            </div>

            <h2 className="text-lg font-semibold mb-4">Server Access Logging</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400">Server access logging</h3>
                <p className="text-sm font-semibold text-gray-800 dark:text-gray-200">Disabled</p>
            </div>

            <h2 className="text-lg font-semibold mb-4">Event Notifications</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400">Event notifications</h3>
                <p className="text-sm font-semibold text-gray-800 dark:text-gray-200">No event notifications</p>
            </div>

            <h2 className="text-lg font-semibold mb-4">Tags</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">You can use bucket tags to track storage costs and organize buckets. <Link to="#" className="text-blue-600 dark:text-blue-400 underline">Learn more</Link></p>
                <p className="text-sm text-gray-500 dark:text-gray-400">No tags associated with this resource.</p>
            </div>

            <h2 className="text-lg font-semibold mb-4">Intelligent-Tiering Archive configurations</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">Enable objects stored in the Intelligent-Tiering storage class to tier down to the Archive Access tier or the Deep Archive Access tier which are optimized for objects that will be rarely accessed for long periods of time. <Link to="#" className="text-blue-600 dark:text-blue-400 underline">Learn more</Link></p>
                <p className="text-sm text-gray-500 dark:text-gray-400">No archive configurations</p>
            </div>

            <h2 className="text-lg font-semibold mb-4">Server access logging</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">Log requests for access to your bucket. Use <Link to="#" className="text-blue-600 dark:text-blue-400 underline">CloudWatch</Link> to check the health of your server access logging.</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">Server access logging: Disabled</p>
            </div>

            <h2 className="text-lg font-semibold mb-4">AWS CloudTrail data events</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">Configure CloudTrail data to log Amazon S3 object-level API operations in the CloudTrail console. <Link to="#" className="text-blue-600 dark:text-blue-400 underline">Learn more</Link></p>
                <p className="text-sm text-gray-500 dark:text-gray-400">No data events to display.</p>
            </div>

            <h2 className="text-lg font-semibold mb-4">Event notifications</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">Send a notification when specific events occur in your bucket. <Link to="#" className="text-blue-600 dark:text-blue-400 underline">Create event notification</Link></p>
                <p className="text-sm text-gray-500 dark:text-gray-400">No event notifications</p>
            </div>

            <h2 className="text-lg font-semibold mb-4">Amazon EventBridge</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">For additional capabilities, use Amazon EventBridge to build event-driven applications at scale using S3 event notifications.</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">Send notifications to Amazon EventBridge for all events in this bucket: Off</p>
            </div>

            <h2 className="text-lg font-semibold mb-4">Transfer acceleration</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">Use an accelerated endpoint for faster data transfers. <Link to="#" className="text-blue-600 dark:text-blue-400 underline">Learn more</Link></p>
                <p className="text-sm text-gray-500 dark:text-gray-400">Transfer acceleration: Disabled</p>
            </div>

            <h2 className="text-lg font-semibold mb-4">Object Lock</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">Store objects using a write-once-read-many (WORM) model to help you prevent objects from being deleted or overwritten for a fixed amount of time or indefinitely. Object Lock works only in versioned buckets.</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">Object Lock: Disabled</p>
            </div>

            <h2 className="text-lg font-semibold mb-4">Requester pays</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">When enabled, the requester pays for requests and data transfer costs, and anonymous access to this bucket is disabled.</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">Requester pays: Disabled</p>
            </div>

            <h2 className="text-lg font-semibold mb-4">Static website hosting</h2>
            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">Use this bucket to host a website or redirect requests.</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">S3 static website hosting: Disabled</p>
            </div>
        </div>
    );
}