import { createSlice } from '@reduxjs/toolkit';
import { loginAdmin, registerAdmin } from './authThunk';
import toast from 'react-hot-toast';

const initialState = {
    isLoading: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginAdmin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(loginAdmin.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                toast.success((payload).message || 'Login successfully.')
            })
            .addCase(loginAdmin.rejected, (state, { payload }) => {
                state.isLoading = false;
                toast.error((payload as Error).message || 'Login Failed')
            })
            .addCase(registerAdmin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(registerAdmin.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                toast.success((payload).message || 'Register successfully.')
            })
            .addCase(registerAdmin.rejected, (state, { payload }) => {
                state.isLoading = false;
                toast.error((payload as Error).message || 'Register Failed')
            })
    },
});

export const authReducer = authSlice.reducer;