import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function BucketPermissions() {
    const [bucketPolicy, setBucketPolicy] = useState(`{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Sid": "PublicRead",
      "Effect": "Allow",
      "Principal": "*",
      "Action": "s3:GetObject",
      "Resource": "arn:aws:s3:::social-broker/*"
    }
  ]
}`);
    const [corsConfig, setCorsConfig] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const handleSubmitConfig = (e: React.FormEvent) => {
        e.preventDefault();
        // Function to handle the submission of the CORS configuration
        console.log("Submitted CORS Configuration:", corsConfig);
        // Add your logic to update the CORS configuration here
        setIsEditing(false); // Exit edit mode after submission
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Function to handle the submission of the bucket policy
        console.log("Submitted Bucket Policy:", bucketPolicy);
        // Add your logic to update the bucket policy here
    };

    return (
        <div className="p-6">
            <h2 className="text-lg font-semibold mb-4">Permissions Overview</h2>

            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-6">
                <h3 className="text-base font-medium text-gray-600 dark:text-gray-400">Access findings</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                    Access findings are provided by IAM external access analyzers. Learn more about
                    <Link to="#" className="text-blue-600 dark:text-blue-400 underline"> how IAM analyzer findings work</Link>.
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                    View analyzer for <span className="font-semibold">ap-southeast-2</span>.
                </p>
            </div>

            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-6">
                <h3 className="text-base font-medium text-gray-600 dark:text-gray-400">Block public access (bucket settings)</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                    Public access is granted to buckets and objects through access control lists (ACLs), bucket policies, access point policies, or all. In order to ensure that public access to all your S3 buckets and objects is blocked, turn on Block all public access. These settings apply only to this bucket and its access points.
                </p>
                <div className="flex items-center mb-2">
                    <span className="font-semibold text-gray-800 dark:text-gray-200">Block all public access</span>
                    <span className="ml-2 text-red-600">⚠️ Off</span>
                </div>
                <Link to="#" className="text-blue-600 dark:text-blue-400 underline">Individual Block Public Access settings for this bucket</Link>
            </div>

            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-6">
                <h3 className="text-base font-medium text-gray-600 dark:text-gray-400">Bucket policy</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                    The bucket policy, written in JSON, provides access to the objects stored in the bucket. Bucket policies don't apply to objects owned by other accounts.
                </p>
                <form onSubmit={handleSubmit}>
                    <textarea
                        value={bucketPolicy}
                        onChange={(e) => setBucketPolicy(e.target.value)}
                        rows={15}
                        className="bg-gray-100 dark:bg-gray-700 p-2 rounded w-full h-full overflow-x-auto"
                        placeholder="Enter your bucket policy here..."
                    />
                    <div className="mt-2">
                        <button type="submit" className="mt-2 text-blue-600 dark:text-blue-400 underline">Submit</button>
                        <button type="button" className="mt-2 ml-2 text-red-600 dark:text-red-400 underline" onClick={() => setBucketPolicy(`{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Sid": "PublicRead",
      "Effect": "Allow",
      "Principal": "*",
      "Action": "s3:GetObject",
      "Resource": "arn:aws:s3:::social-broker/*"
    }
  ]
}`)}>Reset</button>
                    </div>
                </form>
            </div>

            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-6">
                <h3 className="text-base font-medium text-gray-600 dark:text-gray-400">Object Ownership</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                    Control ownership of objects written to this bucket from other AWS accounts and the use of access control lists (ACLs). Object ownership determines who can specify access to objects.
                </p>
                <div className="flex items-center mb-2">
                    <span className="font-semibold text-gray-800 dark:text-gray-200">Object Ownership</span>
                    <span className="ml-2 text-green-600">Bucket owner enforced</span>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400">ACLs are disabled. All objects in this bucket are owned by this account. Access to this bucket and its objects is specified using only policies.</p>
            </div>

            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-6">
                <h3 className="text-base font-medium text-gray-600 dark:text-gray-400">Access control list (ACL)</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                    Grant basic read/write permissions to other AWS accounts.
                </p>
                <div className="bg-blue-100 dark:bg-blue-900 p-3 rounded mb-4">
                    <p className="text-sm text-blue-800 dark:text-blue-200">
                        This bucket has the bucket owner enforced setting applied for Object Ownership.
                        <Link to="#" className="text-blue-600 dark:text-blue-400 underline"> Learn more</Link>.
                    </p>
                </div>

                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead className="bg-gray-50 dark:bg-gray-700">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Grantee</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Objects</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Bucket ACL</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                        <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">
                                Bucket owner (your AWS account)<br />
                                <span className="text-gray-500 dark:text-gray-400">Canonical ID: 091c09c2dbff4670f24203d0c5d1af0f770a2fab16cccf503f206a2290588e</span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">List, Write</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">Read, Write</td>
                        </tr>
                        <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">Everyone (public access)</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">-</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">-</td>
                        </tr>
                        <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">Authenticated users group (anyone with an AWS account)</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">-</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">-</td>
                        </tr>
                        <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">S3 log delivery group</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">-</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">-</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="bg-white dark:bg-gray-800 p-4 rounded shadow mb-6">
                <div className="flex justify-between items-center mb-2">
                    <h3 className="text-base font-medium text-gray-600 dark:text-gray-400">Cross-origin resource sharing (CORS)</h3>
                    <button
                        className="text-blue-600 dark:text-blue-400 underline"
                        onClick={() => setIsEditing(!isEditing)}
                    >
                        {isEditing ? 'Save' : 'Edit'}
                    </button>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                    The CORS configuration, written in JSON, defines a way for client web applications that are loaded in one domain to interact with resources in a different domain.
                </p>
                {isEditing ? (
                    <form onSubmit={handleSubmitConfig}>
                        <textarea
                            value={corsConfig}
                            onChange={(e) => setCorsConfig(e.target.value)}
                            className="bg-gray-100 dark:bg-gray-700 p-2 rounded w-full h-32 overflow-x-auto"
                            placeholder="Enter your CORS configuration here..."
                        />
                        <div className="mt-2">
                            <button type="submit" className="mt-2 text-blue-600 dark:text-blue-400 underline">Submit</button>
                            <button type="button" className="mt-2 ml-2 text-red-600 dark:text-red-400 underline" onClick={() => setCorsConfig('')}>Reset</button>
                        </div>
                    </form>
                ) : (
                    <div className="border border-gray-300 dark:border-gray-600 rounded p-2">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                            {corsConfig || 'No configurations to display.'}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}
