import React, { useState, ReactNode } from 'react';
import Header from '../components/Header/index';
import Sidebar from '../components/Sidebar/index';
import { useLocation } from "react-router-dom";

const PATHS_NOT_NEED_HEADER_FOOTER = ["/auth/signup","/auth/signin"]

const DefaultLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark"
      >
      <div className="flex h-screen overflow-haidden" style={{
        backgroundImage: PATHS_NOT_NEED_HEADER_FOOTER.includes(pathname) ? `url(../assets/images/auth/bg.jpg)` : 'none' // Updated path
      }}>
        {!PATHS_NOT_NEED_HEADER_FOOTER.includes(pathname) && (
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        )}
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          {!PATHS_NOT_NEED_HEADER_FOOTER.includes(pathname) && (
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          )}
          <main className='flex-1 static z-1'>
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10 h-full">
              {children}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
