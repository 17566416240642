import { Link, useParams, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import DataTable from '../../components/DataTable';
import { MdCloudUpload } from "react-icons/md";
import Button from '../../components/Button';
import { MdOutlineAdd } from "react-icons/md";
import Offcanvas from '../../components/Offcanvas';
import BucketDetails from './BucketDetails';
import BucketPermissions from './BucketPermissions';

export type Package = {
  name: string;
  type: string;
  size: string;
  storage_class: string;
  updated_at: string;
};

const packageData = [
  {
    name: 'content/',
    type: `Folder`,
    updated_at: `Jan 13,2023`,
  },
  {
    name: 'content/',
    type: `Folder`,
    updated_at: `Jan 13,2023`,
  },
  {
    name: 'content/',
    type: `Folder`,
    updated_at: `Jan 13,2023`,
  },
  {
    name: 'content/',
    type: `Folder`,
    updated_at: `Jan 13,2023`,
  },
];

const BucketsObjects = () => {
  const { id }: any = useParams()
  const searchParams: any = useSearchParams()
  console.log("search-->>", searchParams)
  const columns = [
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
      Cell: ({ value }: { value: string }) => (
        <Link
          to="/buckets/social-broker/content"
          className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium underline hover:text-blue-500 duration-300 ease-in-out`}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Last modified',
      accessor: 'updated_at',
    },
    {
      Header: 'Size',
      accessor: 'size'
    },
    {
      Header: 'Storage class',
      accessor: 'storage_class'
    },
  ];
  return (
    <>
      <Breadcrumb items={[{ path: "/buckets", name: "Buckets" }, { path: '', name: id }]} />

      <div className="flex flex-col gap-10">
        <div className='flex flex-col gap-4'>
          <div className='flex justify-end items-center gap-4'>
            <Offcanvas title='Bucket Details' body={<BucketDetails />} className='!w-fit'>
              {({ isOpen, setIsOpen }) => (
                <Button onClick={() => setIsOpen(!isOpen)}>
                  Details
                </Button>
              )}
            </Offcanvas>
            <Offcanvas title='Bucket Permissions' body={<BucketPermissions />} className='!w-fit'>
              {({ isOpen, setIsOpen }) => (
                <Button onClick={() => setIsOpen(!isOpen)}>
                  Permissions
                </Button>
              )}
            </Offcanvas>
            <Button to="/bucket-create" icon={<MdOutlineAdd fontSize={22} />}>
              Create Folder
            </Button>
            <Button icon={<MdCloudUpload fontSize={22} />}>
              Upload
            </Button></div>
          <DataTable columns={columns} data={packageData} />
        </div>
      </div >
    </>
  );
};

export default BucketsObjects;
