import React, { useEffect, useState } from 'react';
import ClickOutside from '../../ClickOutside';
import { PER_PAGE, PER_PAGES } from '../../../helper/constants';
interface ItemsPerPageDropdownProps {
    handleChange: (number: number) => void;
}

const ItemsPerPageDropdown: React.FC<ItemsPerPageDropdownProps> = ({ handleChange }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [itemsPerPage, setItemsPerPage,] = useState(PER_PAGE)
    const toggleDropdown = () => setIsOpen(!isOpen)

    useEffect(()=>{
        handleChange(itemsPerPage)
    }, [handleChange, itemsPerPage])
    return (
        <div className='flex gap-2 relative'>
            <button
                id="dropdownDividerButton"
                data-dropdown-toggle="dropdownDivider"
                className="text-white bg-boxdark hover:bg-boxdark focus:bg-boxdark font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-boxdark dark:focus:bg-boxdark"
                type="button"
                onClick={toggleDropdown}
            >
                {itemsPerPage} Per Page
            </button>
            {isOpen && (
                <div
                    className="z-10 bg-boxdark-2 text-white divide-y divide-gray-100 rounded-lg shadow w-30 dark:bg-gray-700 dark:divide-gray-600"
                    id="dropdownMenu"
                    style={{
                        position: 'absolute',
                        bottom: '100%',
                        left: '0',
                        transform: 'translateY(-0.5rem)'
                    }}
                >
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDividerButton">

                        {PER_PAGES.map((number) => (
                            <li key={number}>
                                <ClickOutside onClick={() => setIsOpen(false)} >
                                    <div onClick={() => {
                                        setItemsPerPage(number);
                                        setIsOpen(false)
                                    }} className="block w-full justify-center px-4 py-2 text-white bg-boxdark-2 dark:bg-gray-700 dark:hover:bg-gray-900 hover:bg-gray-600"
                                    >{number}</div>
                                </ClickOutside>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
export default ItemsPerPageDropdown;





