import React, { useEffect, useState } from 'react';

interface DialogProps {
    title: string;
    className?: string;
    body: (props: { isOpen: boolean; setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) => React.ReactNode;
    children: (props: { isOpen: boolean; setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) => React.ReactNode;
}

const Dialog: React.FC<DialogProps> = ({ title, body, children, className = '', ...props }) => {
    const [isOpen, setIsOpen] = useState(false)

    const onClose = () => setIsOpen(false)

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (isOpen && !target.closest('.dialog')) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <>
            {children({ isOpen, setIsOpen })}
            <div className={`fixed top-0 right-0 left-0 bottom-0 z-[39] transition-transform transform duration-300 ${isOpen ? '' : 'translate-x-full'} ${isOpen ? 'bg-black/20 dark:bg-gray-900/80' : 'bg-black-2/20'}`} />
            <div className={`dialog fixed z-[99] h-screen p-4 top-0 right-0 left-0 bottom-0 transition-transform duration-300 bg-boxdark-2/20 dark:bg-boxdark-2 transform flex justify-center items-center ${isOpen ? '' : 'translate-x-full'} ` + className}>
                <div className="bg-white rounded-md overflow-hidden flex items-end justify-center text-center sm:p-0 relative sm:my-8">
                    <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-2xl w-8 h-8 absolute top-2.5 right-2.5 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-black z-10">
                        &times;
                    </button>
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
                        {body({ isOpen, setIsOpen })}
                    </div>
                </div>
                {/* <h5 id="drawer-label" className="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">{title}</h5>   
                <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-2xl w-8 h-8 absolute top-2.5 right-2.5 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
                    &times;
                </button>
                <div className="dialog-body p-4">
                    {body}
                </div> */}
            </div>
        </>
    );
};

export default Dialog;