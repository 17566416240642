import React from 'react';
import ReactPaginate from 'react-paginate';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (selectedItem: { selected: number }) => void;
}
const PaginationComponent: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
    return (
        <ReactPaginate
            breakLabel="..."
            nextLabel={<FaAngleRight />}
            onPageChange={onPageChange}
            pageRangeDisplayed={2}
            pageCount={totalPages}
            previousLabel={<FaAngleLeft />}
            renderOnZeroPageCount={null}
            forcePage={currentPage - 1}
            marginPagesDisplayed={1}
            containerClassName="flex gap-x-2"
            pageLinkClassName="w-8 h-8 flex justify-center items-center hover:bg-gray-200"
            previousLinkClassName="w-8 h-8 flex justify-center items-center hover:bg-gray-200"
            nextLinkClassName="w-8 h-8 flex justify-center items-center hover:bg-gray-200"
            activeLinkClassName="bg-boxdark text-white rounded-md"
            breakLinkClassName="w-8 h-8 flex justify-center items-center"
        />
    );
};
export default PaginationComponent;