import { Link } from "react-router-dom";

interface PageTitleProps {
    to?: string;
    className?: string;
    icon?: any;
    children: any;
    type?: string;
    variant?: 'primary' | 'outlined';
    loading?:boolean;
}

const Button: React.FC<PageTitleProps | any> = ({ variant ='primary', className = "", to = "#", icon = '', type="button", loading=false,children, ...props}) => {
    const baseClasses = "w-fit flex items-center justify-center gap-2.5 rounded-md py-2.5 px-2.5 text-center font-medium transition-colors duration-200 lg:px-8 xl:px-10";
  
  const variantClasses = variant === 'outlined'
      ? "bg-transparent border border-primary text-primary hover:bg-primary hover:text-white"
    : "bg-primary text-white hover:bg-opacity-90 ";
    return (
        <Link
            to={to}
            className={`${baseClasses} ${variantClasses} ${className}`}
            {...props}
        >
            {
                icon && !loading ? icon: null
            }
            {loading ? 'Loading':children}
        </Link>
    )
};

export default Button;
