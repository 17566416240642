import React, { useEffect, useState } from 'react';

interface OffcanvasProps {
    title: string;
    className?: string;
    body: React.ReactNode;
    children: (props: { isOpen: boolean; setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) => React.ReactNode;
}

const Offcanvas: React.FC<OffcanvasProps> = ({ title, body, children,className='',...props }) => {
    const [isOpen, setIsOpen] = useState(false)

    const onClose = () => setIsOpen(false)

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (isOpen && !target.closest('.offcanvas')) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <>  
            {children({isOpen, setIsOpen})}
            <div className={`fixed top-0 right-0 left-0 bottom-0 z-[39] transition-transform transform duration-300 ${isOpen ? '' : 'translate-x-full'} ${isOpen ? 'bg-black/20 dark:bg-gray-900/80' : 'bg-black-2/20'}`} />
            <div className={`offcanvas fixed top-0 right-0 z-[40] h-screen p-4 overflow-y-auto transition-transform duration-300 bg-white w-80 md:max-w-[70vw] dark:bg-boxdark-2 transform ${isOpen ? '' : 'translate-x-full'} ` + className}>
                <h5 id="drawer-label" className="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">{title}</h5>
                <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-2xl w-8 h-8 absolute top-2.5 right-2.5 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
                    &times;
                </button>
                <div className="offcanvas-body p-4">
                    {body}
                </div>
            </div>
        </>
    );
};

export default Offcanvas;