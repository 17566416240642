import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../../services/request';
import { LOGIN_URL, REGISTER_URL } from '../../helper/constants';
import { ISignInSchema, ISignUpSchema } from '../../helper/validation';

interface LoginData {
  data: ISignInSchema,
  handleRedirection: (response: any) => void;
}

interface RegisterData {
  data: ISignUpSchema,
  handleRedirection: (response: any) => void; // Ensure proper typing for handleRedirection
}

interface ApiResponse {
  status: string;
  message: string;
  // Add other properties of the response object as needed
}

export const loginAdmin = createAsyncThunk<ApiResponse, LoginData>(
  'auth/loginAdmin',
  async (data: LoginData, { rejectWithValue }) => {
    const apiCallData = {
      method: 'POST',
      body: JSON.stringify(data.data), // Ensure data is stringified
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await request(
        LOGIN_URL,
        apiCallData
      );

      console.log("Response------login-", response);
      if (response.status === 200 && response.data.token) {
        data.handleRedirection(response.data);
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const registerAdmin = createAsyncThunk<ApiResponse, RegisterData>(
  'auth/registerAdmin',
  async (data: RegisterData, { rejectWithValue }) => {
    const apiCallData = {
      method: 'POST',
      body: JSON.stringify(data.data), // Ensure data is stringified
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await request(REGISTER_URL, apiCallData);
      console.log("Response-------",response);
      
      if (response.data.token) {
        data.handleRedirection(response.data);
        return response; // Ensure this returns the correct ApiResponse type
      } else {
        return rejectWithValue(response); // This should be a valid reject value
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message); // Ensure this is a valid reject value
    }
  }
);